import md5 from 'md5'
import moment from 'moment'
import React, { Component } from 'react'

import Config from '../../config'

const config = Config(process.env.REACT_APP_ENV)

const formattedDateAt = (dateAt, formatString) =>
  moment(dateAt).format(formatString)

class Logs extends Component {
  state = {
    level: '',
    refetchedAt: null
  }

  filtered = logs => {
    const { level } = this.state

    if (!level) {
      return logs.edges
    }

    return logs.edges.filter(({ node }) => node.level === level)
  }

  filterByLevel = e => {
    e.preventDefault()
    this.setState({ level: e.currentTarget.dataset.level })
  }

  renderPictureUrl = ({ id, firstName, lastName, pictureUrl }) => {
    let backgroundImage = ''
    if (!pictureUrl) {
      backgroundImage = `url(https://www.gravatar.com/avatar/${md5(
        encodeURIComponent(id)
      )}.png?d=robohash&s=64)`
    } else {
      const basename = url => url.substr(url.lastIndexOf('/') + 1)
      backgroundImage = `url(https://${config.imgixHost}/${basename(
        pictureUrl
      )}?w=32&h=32&fit=crop&mask=ellipse&dpr=2&fm=png32)`
    }

    return (
      <a href={`/users/${id}/edit`}>
        <span className='avatar' style={{ backgroundImage }} />
      </a>
    )
  }

  renderRow = ({ node }) => {
    const formattedMessage = message => {
      const [, screen, body] = message.match(/\[(.*)\] (.*)/) || []
      return [screen, body].filter(v => v)
    }

    return (
      <tr key={node.id}>
        <td>{node.user && this.renderPictureUrl(node.user)}</td>
        <td>
          <span style={{ fontSize: '0.8em', color: '#999' }}>
            {node.uniqueId}
          </span>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: (formattedMessage(node.message)[1] || node.message || '')
                .replace(/","/g, '", "')
                .replace(/\n/g, '<br />')
            }}
          />
          <br />
          <span style={{ fontSize: '0.8em', color: '#999' }}>
            {formattedMessage(node.message)[0]}
          </span>
          <br />
          <div
            style={{ fontSize: 10 }}
            dangerouslySetInnerHTML={{
              __html: (node.stacktrace || '')
                .replace(/","/g, '", "')
                .replace(/\n/g, '<br />')
            }}
          />
        </td>
        <td>
          <span>{node.level}</span>
        </td>
        <td>
          <span style={{ fontSize: '0.8em', color: '#999' }}>
            {node.logger}
          </span>
          <br />
          <span>{node.version || 'N/A'}</span>
          <br />
          <span style={{ fontSize: '0.8em', color: '#999' }}>
            {node.platform}
          </span>
          <br />
        </td>
        <td>
          <nobr>
            {formattedDateAt(node.createdAt, 'HH:mm:ss')}
            <br />
            <span style={{ fontSize: '0.8em', color: '#999' }}>
              {formattedDateAt(node.createdAt, 'YYYY-MM-DD')}
            </span>
          </nobr>
        </td>
      </tr>
    )
  }

  render () {
    return (
      <div className='my-3 my-md-5'>
        <div className='container'>
          <div className='row row-cards'>
            <div className='col-lg-12'>
              <div style={{ paddingBottom: '8px' }}>
                <button
                  className='btn btn-success'
                  onClick={() =>
                    window.open(
                      'https://cloudwatch.amazonaws.com/dashboard.html?dashboard=Overview&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTg3ODQ5MzExNTMwNSIsIlUiOiJ1cy1lYXN0LTFfenVuNXdRek82IiwiQyI6IjFybjFkNjlwM2V1cWg1dWE2ZzBuOWJvcjIiLCJJIjoidXMtZWFzdC0xOjNkZmZhZjMzLTUwODUtNDZjNC05MGE4LWU2ZTU4ZWI4NzU0OSIsIk8iOiJhcm46YXdzOmlhbTo6ODc4NDkzMTE1MzA1OnJvbGUvc2VydmljZS1yb2xlL0Nsb3VkV2F0Y2hEYXNoYm9hcmQtUHVibGljLVJlYWRPbmx5QWNjZXNzLU92ZXJ2aWV3LTQwMVVZNElQIiwiTSI6IlB1YmxpYyJ9',
                      '_blank'
                    )
                  }
                >
                  Open AWS Overview
                </button>{' '}
                <button
                  className='btn btn-success'
                  onClick={() =>
                    window.open(
                      'https://app.posthog.com/shared/PqL8q1klIdTlKKX5tpGcwRV3A2dFmQ',
                      '_blank'
                    )
                  }
                >
                  Open PostHog
                </button>
              </div>

              <iframe
                name='awsOverview'
                title='AWS Overview'
                src='https://cloudwatch.amazonaws.com/dashboard.html?dashboard=Overview&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTg3ODQ5MzExNTMwNSIsIlUiOiJ1cy1lYXN0LTFfenVuNXdRek82IiwiQyI6IjFybjFkNjlwM2V1cWg1dWE2ZzBuOWJvcjIiLCJJIjoidXMtZWFzdC0xOjNkZmZhZjMzLTUwODUtNDZjNC05MGE4LWU2ZTU4ZWI4NzU0OSIsIk8iOiJhcm46YXdzOmlhbTo6ODc4NDkzMTE1MzA1OnJvbGUvc2VydmljZS1yb2xlL0Nsb3VkV2F0Y2hEYXNoYm9hcmQtUHVibGljLVJlYWRPbmx5QWNjZXNzLU92ZXJ2aWV3LTQwMVVZNElQIiwiTSI6IlB1YmxpYyJ9'
                width='100%'
                height='800'
                frameBorder='0'
                style={{
                  width: '100%',
                  height: '85vh',
                  border: 'none',
                  margin: 0,
                  padding: 0
                }}
              />

              <iframe
                name='postHogOverview'
                title='PostHog Overview'
                width='100%'
                height='800'
                frameborder='0'
                style={{
                  width: '100%',
                  height: '85vh',
                  border: 'none',
                  margin: 0,
                  padding: 0
                }}
                src='https://app.posthog.com/embedded/PqL8q1klIdTlKKX5tpGcwRV3A2dFmQ'
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { Logs }
