/**
 * @generated SignedSource<<5839780ee22ecec7a9850b8e1a3703b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ownerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ownerType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "ownerId",
        "variableName": "ownerId"
      },
      {
        "kind": "Variable",
        "name": "ownerType",
        "variableName": "ownerType"
      },
      {
        "kind": "Literal",
        "name": "status",
        "value": "FAILED"
      }
    ],
    "concreteType": "Integration",
    "kind": "LinkedField",
    "name": "updateIntegrationById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ownerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ownerType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IntegrationsStopMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IntegrationsStopMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9d2530043f497b73117fef7417eb2984",
    "id": null,
    "metadata": {},
    "name": "IntegrationsStopMutation",
    "operationKind": "mutation",
    "text": "mutation IntegrationsStopMutation(\n  $id: Uuid!\n  $ownerId: Uuid\n  $ownerType: ParticipantAssociationType\n) {\n  updateIntegrationById(id: $id, ownerId: $ownerId, ownerType: $ownerType, status: FAILED) {\n    id\n    ownerId\n    ownerType\n    status\n  }\n}\n"
  }
};
})();

node.hash = "b6a151239956f57f0cac9b7210195c44";

module.exports = node;
