/**
 * @generated SignedSource<<bedff18065013f5f0a3e4dfecfe2b9de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": "integrations",
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "status",
            "variableName": "status"
          }
        ],
        "kind": "ObjectValue",
        "name": "condition"
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 666
      }
    ],
    "concreteType": "IntegrationsConnection",
    "kind": "LinkedField",
    "name": "allIntegrations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "IntegrationsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": "integration",
            "args": null,
            "concreteType": "Integration",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownerId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownerType",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": "application",
                "args": null,
                "concreteType": "Application",
                "kind": "LinkedField",
                "name": "applicationByApplicationId",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IntegrationsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IntegrationsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "0a3ad1121338a7b98dafc60f9f519bde",
    "id": null,
    "metadata": {},
    "name": "IntegrationsQuery",
    "operationKind": "query",
    "text": "query IntegrationsQuery(\n  $status: IntegrationStatus\n) {\n  integrations: allIntegrations(first: 666, condition: {status: $status}) {\n    totalCount\n    edges {\n      integration: node {\n        id\n        ownerId\n        ownerType\n        name\n        application: applicationByApplicationId {\n          id\n          name\n        }\n        status\n        updatedAt\n        scheduledAt\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "ec00afd2bc0c785e2ae93752772790bc";

module.exports = node;
