import { graphql } from 'babel-plugin-relay/macro'
import React, { useCallback, useState } from 'react'
import { QueryRenderer, commitMutation } from 'react-relay'
import { useSearchParams } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Loader } from '../../components'
import Config from '../../config'
import { INTEGRATIONS_QUERY } from './Integrations.graphql'

const config = Config(process.env.REACT_APP_ENV)

const IntegrationStatuses = ['IDLE', 'RUNNING', 'COMPLETED', 'FAILED']

const Integrations = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedStatus, setStatus] = useState(searchParams.get('status'))

  const handleStatusChange = useCallback(
    (status) => {
      setStatus(status)
      if (status) {
        setSearchParams({ ...Object.fromEntries(searchParams), status })
      } else {
        const newParams = new URLSearchParams(searchParams)
        newParams.delete('status')
        setSearchParams(newParams)
      }
    },
    [searchParams, setSearchParams]
  )

  const handleStopIntegration = useCallback((integration) => {
    console.log('integration', integration)
    commitMutation(environment, {
      mutation: graphql`
        mutation IntegrationsStopMutation($id: Uuid!, $ownerId: Uuid, $ownerType: ParticipantAssociationType) {
          updateIntegrationById(id: $id, ownerId: $ownerId, ownerType: $ownerType, status: FAILED) {
            id
            ownerId
            ownerType
            status
          }
        }
      `,
      variables: {
        id: integration.id,
        ownerId: integration.ownerId,
        ownerType: integration.ownerType
      },
      onCompleted: () => {
        // Optional: Add success notification
        console.log('Integration stopped')
      },
      onError: (error) => {
        console.error('Failed to stop integration:', error)
        // Optional: Add error notification
      }
    })
  }, [])

  const renderFilterStatus = useCallback(() => {
    return (
      <div className='dropdown'>
        <button type='button' className='btn btn-secondary dropdown-toggle' data-toggle='dropdown'>
          <i className='fe fe-filter mr-2'></i>
          {selectedStatus ?? 'Status'}
        </button>
        <ul className='dropdown-menu' aria-labelledby='dropdownFilterButton'>
          <li>
            <button type='button' className='dropdown-item' onClick={() => handleStatusChange(null)}>
              All
            </button>
          </li>
          {IntegrationStatuses.map((status) => (
            <li key={status}>
              <button type='button' className='dropdown-item' onClick={() => handleStatusChange(status)}>
                {status}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }, [selectedStatus, handleStatusChange])

  return (
    <QueryRenderer
      environment={environment}
      query={INTEGRATIONS_QUERY}
      variables={{
        status: selectedStatus
      }}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        const integrations = props.integrations?.edges?.map((ed) => ed.integration) ?? []

        const totalCount = props.integrations?.totalCount

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row row-cards'>
                <div className='col-lg-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <div>
                        <h3 className='card-title'>{`Integrations (${totalCount})`}</h3>
                      </div>
                      <div className='ml-auto'>{renderFilterStatus()}</div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table card-table table-striped table-vcenter'>
                        <thead>
                          <tr>
                            <th className='d-none d-lg-table-cell'>Name</th>
                            <th className='d-none d-lg-table-cell'>Application</th>
                            <th className='d-none d-lg-table-cell'>Status</th>
                            <th className='d-none d-lg-table-cell'>Scheduled At</th>
                            <th className='d-none d-lg-table-cell'>Updated At</th>
                            <th className='d-none d-lg-table-cell'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {integrations.map((integration) => {
                            return (
                              <tr key={integration.id}>
                                <td>{integration.name}</td>
                                <td>{integration.application?.name}</td>
                                <td>{integration.status}</td>
                                <td>{integration.scheduledAt}</td>
                                <td>{integration.updatedAt}</td>
                                <td>
                                  {integration.status === 'RUNNING' ? (
                                    <button type='button' className='btn btn-danger' onClick={() => handleStopIntegration(integration)}>
                                      <i className='fe fe-stop-circle'></i>
                                    </button>
                                  ) : undefined}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { Integrations }
