import graphql from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'

import environment from './Environment'

const commitMutationAsPromise = (environment, options) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      ...options,
      onError: err => {
        console.error('we got an mutation error', err)
        return reject(err)
      },
      onCompleted: (rsp, errors) => {
        if (errors) {
          console.error('Failed to execute GraphQL mutation', errors.map(e => e.message).join(', '))
          return reject(errors)
        }

        return resolve(rsp)
      }
    })
  })
}

const authenticateByAdmin = ({ email, password }) => {
  return commitMutationAsPromise(environment, {
    mutation: graphql`
      mutation apiAuthenticateByAdminMutation($email: String!, $password: String!) {
        authenticateByAdmin(email: $email, password: $password) {
          jwt
        }
      }
    `,
    variables: {
      email,
      password
    }
  })
}

const createPartnerPhoto = ({ partnerId, url, caption, description }) => {
  const mutation = graphql`
    mutation apiPartnerPhotosCreateMutation($input: CreatePartnerPhotoInput!) {
      createPartnerPhoto(input: $input) {
        partnerPhoto {
          id
        }
      }
    }
  `

  const variables = {
    input: {
      partnerPhoto: {
        partnerId,
        url,
        caption,
        description
      }
    }
  }

  return commitMutationAsPromise(
    environment, {
      mutation,
      variables
    }
  )
}

const deletePartnerPhoto = ({ id }) => {
  const mutation = graphql`
      mutation apiPartnerPhotosDeleteMutation($input: DeletePartnerPhotoByIdInput!) {
        deletePartnerPhotoById(input: $input) {
          deletedPartnerPhotoId
        }
      }
    `

  const variables = {
    input: {
      id
    }
  }

  return commitMutationAsPromise(
    environment, {
      mutation,
      variables
    }
  )
}

const createPlacePhoto = ({ placeId, url, caption, description }) => {
  const mutation = graphql`
    mutation apiPlacePhotosCreateMutation($input: CreatePlacePhotoInput!) {
      createPlacePhoto(input: $input) {
        placePhoto {
          id
        }
      }
    }
  `

  const variables = {
    input: {
      placePhoto: {
        placeId,
        url,
        caption,
        description
      }
    }
  }

  return commitMutationAsPromise(
    environment, {
      mutation,
      variables
    }
  )
}

const deletePlacePhoto = ({ id }) => {
  const mutation = graphql`
      mutation apiPlacePhotosDeleteMutation($input: DeletePlacePhotoByIdInput!) {
        deletePlacePhotoById(input: $input) {
          deletedPlacePhotoId
        }
      }
    `

  const variables = {
    input: {
      id
    }
  }

  return commitMutationAsPromise(
    environment, {
      mutation,
      variables
    }
  )
}

export {
  authenticateByAdmin,

  createPartnerPhoto, createPlacePhoto, deletePartnerPhoto, deletePlacePhoto
}

